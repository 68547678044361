import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { STATE } from "modules/role/types";
import { stateReducer } from "modules/role/reducers";
import { useNavigate } from "react-router-dom";
import {
  addPermissions,
  find,
  getAllPermissions,
  getPermissions,
} from "modules/role/services";
import { mapPermissions, mapRole } from "utils/map-data";
import {
  SET_ALL_PERMISSIONS,
  SET_ITEM,
  SET_PERMISSIONS,
} from "modules/role/reducers/action-types";
import Grid from "@mui/material/Grid";
import PermissionList from "modules/role/components/permission-list";
import Container from "@mui/material/Container";
import Button from "components/Button";
import Typography from "@mui/material/Typography";
import { parseInt } from "lodash";

const APP_STATE: STATE = {
  isDialogOpen: false,
  isConfirmDialogOpen: false,
  item: {
    id: "",
    name: "",
    code: "",
    description: "",
    permissions: [],
  },
  data: [],
  permissions: [],
  allPermissions: [],
};

export const RolePermission: React.FC = () => {
  const params = useParams();
  const [state, setState] = React.useReducer(stateReducer, APP_STATE);
  const navigate = useNavigate();

  useEffect(() => {
    findRole();
    loadPermissions();

    return () => { };
  }, []);

  const findRole = async () => {
    const response = await find(params.id);
    if (response.status === 200) {
      const role = mapRole(response.data.data);
      setState({ type: SET_ITEM, payload: role });
    }
  };

  const loadPermissions = async () => {
    const response = await getPermissions();
    const allResult = await getAllPermissions();
    if (response.status === 200) {
      const permissions = (response.data.data);
      const alllPermissions = mapPermissions(allResult.data.data);

      const results = Object.keys(permissions).map((key) => ({
        name: key,
        permissions: mapPermissions(permissions[key]),
      }));

      setState({ type: SET_PERMISSIONS, payload: results });
      setState({ type: SET_ALL_PERMISSIONS, payload: alllPermissions });
    }
  };

  const submitForm = async () => {
    const payload = {
      role_id: state.item.id,
      entries: state.item.permissions.map((i) => i.id),
    };
    const response = await addPermissions(payload);
    if (response.status === 201) {
      navigate(`/manage-roles`);
    }
  };

  const handleItemCheck = (id: string) => {
    const role = state.item;
    const permissions = state.allPermissions;
    const idx = role.permissions.map((i) => parseInt(i.id)).indexOf(
      parseInt(id),
    );

    if (idx !== -1) {
      role.permissions.splice(idx, 1);
    } else {
      const _item = permissions.find((entry) =>
        parseInt(entry.id) === parseInt(id)
      );
      role.permissions.push(_item);
    }
    setState({ type: SET_ITEM, payload: role });
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item style={{ padding: "15px" }}>
                {state.item &&
                  (
                    <Typography variant="h5" noWrap component="div">
                      Permissions for {state.item.name} Role
                    </Typography>
                  )}
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submitForm}
                  label="Save Permissions"
                >
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {state.permissions.map((permission, idx) => (
            <PermissionList
              key={idx}
              permission={permission}
              onItemCheked={handleItemCheck}
              selectedItems={state.item.permissions}
              onSubmit={submitForm}
            />
          ))}
        </Grid>
        {/* <pre>{JSON.stringify(state.item.permissions, null, 4)}</pre> */}
      </Container>
    </>
  );
};

export default RolePermission;
