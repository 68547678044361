import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { canClick } from "utils/can";
import "modules/dashboard/components/institution.scss";
import {
  generatePayload,
  getUserInfo,
} from "modules/institution/services/institution-service";
import Dialog from "components/Dialog";
import ConfirmDialog from "components/ConfirmDialog";
import CompanySelectionForm from "modules/dashboard/forms/company-selection";

interface Props {
  id: number;
  name: string;
  description: string;
  url: string;
  imageUrl: string;
}

interface Item {
  item: Props;
}

interface Company {
  id: number;
  name: string;
  description: string;
  system_id: string;
  logo_path: string;
  url: string;
  imageUrl: string;
}

interface Props {
  companies: Array<Company>;
  onSubmit: Function;
}

const STATE = {
  isDialogOpen: false,
  isConfirmDialogOpen: false,
  name: "",
  companies: [],
};

const InstitutionName = (props: any) => (
  <h2 style={{ fontSize: "18px" }}>{props.name.toUpperCase()}</h2>
);

const InstitutionCard: React.FC<Item> = (props: Item) => {
  const { id, name, imageUrl, url: redirectUrl } = props.item;

  const [state, setState] = useState(STATE);

  const paperStyles = {
    marginBottom: "20px",
  };

  const redirect = async (evt: any) => {
    evt.preventDefault();
    if (canClick(id)) {
      const response = await getUserInfo(id);
      if (response.data.data.length > 1) {
        setState((prevState) => ({
          ...prevState,
          companies: response.data.data,
          isDialogOpen: true,
        }));
      } else {
        // just get payload & redirect
        const payload = response.data.data[0].tala_reg_no;
        const res = await generatePayload(id, { "tala_reg_no": payload });
        const result = res.data.data;
        window.open(`${result}`, "_blank");
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        name: name,
        isConfirmDialogOpen: true,
      }));
    }
  };

  const loadPayload = async (values: any) => {
    const response = await generatePayload(id, values);
    const payload = response.data.data;
    window.open(`${payload}`, "_blank");
  };

  const closeDialog = (val: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isDialogOpen: val,
    }));
  };

  const closeConfirmDialog = () => {
    setState((prevState) => ({
      ...prevState,
      name: "",
      isConfirmDialogOpen: false,
    }));
  };

  return (
    <Grid item xs={12} md={3} lg={3}>
      <a onClick={(event) => redirect(event)} href="#">
        <Paper elevation={3} style={paperStyles}>
          <Grid
            spacing={2}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  paddingBottom: "15px",
                }}
              >
                <img src={imageUrl} height={60} />
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  backgroundColor: "#009688",
                  color: "#fff",
                }}
              >
                <InstitutionName name={name} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </a>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        title="Company Selection"
        open={state.isDialogOpen}
        onCancel={closeDialog}
      >
        <CompanySelectionForm
          onSubmit={loadPayload}
          companies={state.companies}
        />
      </Dialog>

      <ConfirmDialog
        title={`You are not registered with ${state.name.toUpperCase()}`}
        isOpen={state.isConfirmDialogOpen}
        text={`You must be a registered user with ${state.name.toUpperCase()} to access their services`}
      >
        <Button onClick={closeConfirmDialog}>Cancel</Button>
        <Button
          onClick={closeConfirmDialog}
          variant="outlined"
          className="p-10"
        >
          Register with {state.name.toUpperCase()}
        </Button>
      </ConfirmDialog>
    </Grid>
  );
};

export default InstitutionCard;
