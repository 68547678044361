import React, { useEffect } from "react";
import Dialog from "components/Dialog";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import PencilBoxOutline from "mdi-react/PencilBoxIcon";
import TrashCanOutline from "mdi-react/TrashCanIcon";
import SettingsIcon from "@mui/icons-material/Settings";

import Table from "components/Table";
import ConfirmDialog from "components/ConfirmDialog";
import { Slot } from "components/Slot";
import {
  CLOSE_CONFIRM_DIALOG,
  CLOSE_DIALOG,
  OPEN_CONFIRM_DIALOG,
  OPEN_DIALOG,
  SET_DATA,
  SET_FORM_DATA,
  SET_ITEM,
  SET_TITLE,
} from "modules/role/reducers/action-types";
import { create, deleteRole, get, updateRole } from "modules/role/services";
import { Role, STATE } from "modules/role/types";
import { formDataReducer, stateReducer } from "modules/role/reducers";
import { mapRoles } from "utils/map-data";
import RoleForm from "modules/role/forms/role-form";

const COLUMNS = [
  { id: 1, value: "name", label: "Name" },
  { id: 2, value: "code", label: "Code" },
  { id: 4, value: "description", label: "Description" },
  { id: 5, value: "actions", label: "Actions" },
];

const APP_STATE: STATE = {
  isDialogOpen: false,
  isConfirmDialogOpen: false,
  item: {
    id: "",
    name: "",
    code: "",
    description: "",
    permissions: [],
  },
  data: [],
  permissions: [],
  allPermissions: [],
};

const FORM_DATA: Role = {
  id: "",
  name: "",
  code: "",
  description: "",
  permissions: [],
};

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(stateReducer, APP_STATE);
  const [formData, setFormData] = React.useReducer(
    formDataReducer,
    FORM_DATA,
  );

  useEffect(() => {
    fetchData();

    return () => { };
  }, []);

  const fetchData = async () => {
    get({}).then((response) => {
      setState({ type: SET_DATA, payload: mapRoles(response.data.data.data) });
    });
  };

  const setOpenDialog = (_val: boolean) => {
    setState({ type: CLOSE_DIALOG });
  };

  const openDialog = (role: Role) => {
    if (role && role.id) {
      setFormData({ type: SET_FORM_DATA, payload: role });
      setState({ type: SET_TITLE, payload: "Update" });
    } else {
      setState({ type: SET_TITLE, payload: "Create" });
      setFormData({ type: SET_FORM_DATA, payload: FORM_DATA });
    }
    setState({ type: OPEN_DIALOG });
  };

  const closeConfirmDialog = () => {
    setState({ type: CLOSE_CONFIRM_DIALOG });
  };

  const deleteItem = async () => {
    const result = await deleteRole(state.item.id);
    if (result.status === 200) {
      setState({ type: CLOSE_CONFIRM_DIALOG });
      await fetchData();
    }
  };

  const openConfirmDialog = (role: Role) => {
    setState({ type: OPEN_CONFIRM_DIALOG });
    setState({ type: SET_ITEM, payload: role });
  };

  const navigateToPermission = async (role: Role) => {
    navigate(`/manage-roles/${role.id}`);
  };

  interface Payload {
    id?: string | number;
    name: string;
    code: string;
    description: string;
  }

  const submitForm = async (payload: Payload) => {
    if (payload && payload.id) {
      const result = await updateRole(payload.id, payload);
      if (result.status === 200) {
        setState({ type: CLOSE_DIALOG });
        await fetchData();
      }
    } else {
      const result = await create(payload);
      if (result.status === 201) {
        setState({ type: CLOSE_DIALOG });
        await fetchData();
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ padding: "15px" }}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Typography variant="h5" noWrap component="div">
                Manage Roles
              </Typography>
            </Grid>
            <Grid item sm={9}></Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => openDialog(null)}
              >
                Create New
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        {state.data.length > 0 &&
          (
            <Table
              columns={COLUMNS}
              onItemClick={fetchData}
              size="small"
              items={state.data}
            >
              {({ row }) => (
                <Slot name="actions">
                  <Tooltip title="Edit Role" placement="top" arrow>
                    <IconButton onClick={() => openDialog(row)}>
                      <PencilBoxOutline />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Role" placement="top" arrow>
                    <IconButton onClick={() => openConfirmDialog(row)}>
                      <TrashCanOutline />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Setup Permissions" placement="top" arrow>
                    <IconButton onClick={() => navigateToPermission(row)}>
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </Slot>
              )}
            </Table>
          )}
      </Grid>

      <Dialog
        fullWidth
        maxWidth={"sm"}
        title={`${state.title} Role`}
        open={state.isDialogOpen}
        onCancel={setOpenDialog}
      >
        <RoleForm
          onSubmit={(values) => submitForm(values)}
          FORM_DATA={formData}
        />
      </Dialog>

      <ConfirmDialog
        title="Delete Role"
        isOpen={state.isConfirmDialogOpen}
        text="Are you sure you want to delete this role?"
      >
        <Button onClick={closeConfirmDialog}>Cancel</Button>
        <Button onClick={deleteItem}>Ok</Button>
      </ConfirmDialog>
    </>
  );
};

export default Home;
