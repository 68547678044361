import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

import "components/Preloader/style.scss";

const Loader = () => {
  const state = useSelector((state: RootState) => state.preloader);
  return (
    <>
      {state.loading && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <div className="loadingio-spinner-eclipse-zdin78qjk2">
              <div className="ldio-5hg5t3mtcqb">
                <div></div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Loader;
