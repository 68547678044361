import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  OPEN_CONFIRM_DIALOG,
  CLOSE_CONFIRM_DIALOG,
  SET_DATA,
  SET_INSTITUTION,
  SET_TITLE,
} from "modules/institution/reducers/action-types";

interface ACTION {
  type: string;
  payload?: any;
}

interface Institution {
  id: number;
  name: string;
  system_id: string;
  url: string;
  description: string;
}

interface APP_STATE {
  isDialogOpen: boolean;
  isConfirmDialogOpen: boolean;
  institution: Institution;
  data: Array<any>;
  title: string;
}

const STATE: APP_STATE = {
  isDialogOpen: false,
  isConfirmDialogOpen: false,
  institution: {
    id: 1,
    name: "",
    system_id: "",
    url: "",
    description: "",
  },
  data: [],
  title: "",
};

const stateReducer = (state: APP_STATE, action: ACTION) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return { ...state, isDialogOpen: true };

    case CLOSE_DIALOG:
      return { ...state, isDialogOpen: false, title: "", institution: {} };

    case OPEN_CONFIRM_DIALOG:
      return { ...state, isConfirmDialogOpen: true };

    case CLOSE_CONFIRM_DIALOG:
      return { ...state, isConfirmDialogOpen: false };

    case SET_DATA:
      return { ...state, data: action.payload };

    case SET_INSTITUTION:
      return { ...state, institution: action.payload };

    case SET_TITLE:
      return { ...state, title: action.payload };

    default:
      return state;
  }
};

export { stateReducer };
