import Dialog from "components/Dialog";
import LoginForm from "modules/auth/login-form";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

export const LoginDialog = () => {
  const state = useSelector((state: RootState) => state.loginDialog);
  const hideDialog = (_val: boolean) => {};

  return (
    <Dialog
      open={state.showDialog}
      onCancel={hideDialog}
      title="YOUR TOKEN HAS EXPIRED"
    >
      <LoginForm />
    </Dialog>
  );
};

export default LoginDialog;
