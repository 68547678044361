import { SHOW_LOGIN_DIALOG, HIDE_LOGIN_DIALOG } from "store/reducers/login-dialog/action-types";
import { PAYLOAD as STATE } from "store/reducers/password-change/actions";

const initialState: STATE = {
  showDialog: false,
}

interface ACTION {
  type: string;
  payload: STATE;
}


const reducer = (state = initialState, action: ACTION) :STATE => {
  switch (action.type) {
    case SHOW_LOGIN_DIALOG:
      return {...state, showDialog: true}

    case HIDE_LOGIN_DIALOG:
      return {...state, showDialog: false}

    default:
    return state;
  }
}

export default reducer;
