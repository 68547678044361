export const OPEN_DIALOG = "OPEN_DIALOG";
export const CLOSE_DIALOG = "CLOSE_DIALOG";
export const OPEN_CONFIRM_DIALOG = "OPEN_CONFIRM_DIALOG ";
export const CLOSE_CONFIRM_DIALOG = "CLOSE_CONFIRM_DIALOG ";
export const SET_DATA = "SET_DATA";
export const SET_ITEM = "SET_ITEM ";
export const SET_FORM_DATA = "SET_FORM_DATA ";
export const RESET_FORM_DATA = "RESET_FORM_DATA ";
export const SET_PERMISSIONS = "SET_PERMISSIONS ";
export const SET_ALL_PERMISSIONS = "SET_ALL_PERMISSIONS ";
export const SET_TITLE = "SET_TITLE ";
