import React from "react";
import Avatar from "@mui/material/Avatar";
import { green } from "@mui/material/colors";

interface Props {
  name: string;
}

const Avatr: React.FC<Props> = ({ name }) => {
  const nameArray = name.split(" ");
  const [first, last] = name.split(" ");
  const initials = nameArray.length > 1
    ? `${first[0]} ${last[0]}`
    : `${first[0]}`;
  return (
    <Avatar sx={{ bgcolor: green[400], fontWeight: "bold", fontSize: "14px" }}>
      {initials}
    </Avatar>
  );
};

export default Avatr;
