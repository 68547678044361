import Dialog from "components/Dialog";
import ChangePassword from "modules/auth/change-password";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

export const PasswordDialog = () => {
  const state = useSelector((state: RootState) => state.passwordDialog);
  const hideDialog = (_val: boolean) => { };

  return (
    <Dialog
      open={state.showDialog}
      onCancel={hideDialog}
      title="Change Password"
    >
      <ChangePassword />
    </Dialog>
  );
};

export default PasswordDialog;
