import React, { useEffect } from "react";
import Dialog from "components/Dialog";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { FormikValues } from "formik";
import axios from "api/axios";

import PencilBoxOutline from "mdi-react/PencilBoxIcon";
import TrashCanOutline from "mdi-react/TrashCanIcon";

import CreateForm from "modules/institution/form/create";
import Table from "components/Table";
import ConfirmDialog from "components/ConfirmDialog";
import { Slot } from "components/Slot";
import {
  deleteItem,
  getAll,
} from "modules/institution/services/institution-service";
import Typography from "@mui/material/Typography";
import { stateReducer } from "modules/institution/reducers";
import {
  CLOSE_CONFIRM_DIALOG,
  CLOSE_DIALOG,
  OPEN_CONFIRM_DIALOG,
  OPEN_DIALOG,
  SET_DATA,
  SET_INSTITUTION,
  SET_TITLE,
} from "modules/institution/reducers/action-types";

const COLUMNS = [
  { id: 1, value: "name", label: "Institution Name" },
  { id: 2, value: "system_id", label: "System Identifier" },
  { id: 3, value: "url", label: "Redirect URL" },
  { id: 4, value: "description", label: "Description" },
  { id: 5, value: "actions", label: "Actions" },
];

interface Institution {
  id?: number | string;
  name: string;
  system_id: string;
  url: string;
  description: string;
}

interface APP_STATE {
  isDialogOpen: boolean;
  isConfirmDialogOpen: boolean;
  institution: Institution;
  data: Array<any>;
  title: string;
}

const STATE: APP_STATE = {
  isDialogOpen: false,
  isConfirmDialogOpen: false,
  institution: {
    id: "",
    name: "",
    system_id: "",
    url: "",
    description: "",
  },
  title: "",
  data: [],
};

const FORM_DATA = {
  id: "",
  name: "",
  description: "",
  system_id: "",
  logo_path: "",
  alias: "",
  keypass: "",
  url: "",
};

interface STATE {
  selectedFiles: any;
  currentFile: any;
  progress: number;
  message: string;
  isError: boolean;
  fileInfos: Array<any>;
  totalElements: number;
}

const UPLOAD_STATE: STATE = {
  selectedFiles: null,
  currentFile: null,
  progress: 0,
  message: "",
  isError: false,
  fileInfos: [],
  totalElements: 0,
};

export const Home: React.FC = () => {
  // const [state, setState] = React.useState(STATE);
  const [uploadState, setUploadState] = React.useState(UPLOAD_STATE);
  const [formData, setFormData] = React.useState(FORM_DATA);

  const [state, setState] = React.useReducer(stateReducer, STATE);

  const mapData = (results: any) => {
    return results.map((entry: any) => ({
      id: entry.id,
      name: entry.name,
      system_id: entry.system_id,
      url: entry.url,
      keypass: entry.keypass,
      alias: entry.alias,
      description: entry.description,
    }));
  };

  useEffect(() => {
    getAll({}).then((response) => {
      setState({ type: SET_DATA, payload: mapData(response.data.data) });
    });
  }, []);

  const setOpenDialog = (_val: boolean) => {
    setState({ type: CLOSE_DIALOG });
    resetFormData();
  };

  const openDialog = (item: any = null) => {
    if (item && item.id) {
      setState({ type: SET_TITLE, payload: "Update" });
      setFormData((prevState) => ({
        ...prevState,
        ...item,
      }));
    } else {
      setState({ type: SET_TITLE, payload: "Create" });
      setFormData((prevState) => ({
        ...prevState,
      }));
    }
    setState({ type: OPEN_DIALOG });
  };

  const resetFormData = () => {
    setFormData((prevState) => ({
      ...prevState,
      id: "",
      name: "",
      description: "",
      system_id: "",
      logo_path: "",
      alias: "",
      keypass: "",
      url: "",
    }));
  };

  const submitForm = async (values: FormikValues) => {
    const API = "/institutions";
    const currentFile = uploadState.selectedFiles[0];

    setUploadState((prevState) => ({
      ...prevState,
      progress: 0,
      currentFile: currentFile,
    }));

    const fd = new FormData();
    fd.append("logo_path", currentFile, currentFile.name);
    Object.entries(values).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        // might also just use join() for comma separated string
        v = JSON.stringify(v);
      }
      fd.append(k, v);
    });

    const updateProgress = (progressEvent: any) => {
      setUploadState((prevState) => ({
        ...prevState,
        progress: Math.round(
          (100 * progressEvent.loaded) / progressEvent.total,
        ),
      }));
    };

    const updateSuccess = (response: any) => {
      setUploadState((prevState) => ({
        ...prevState,
        message: response.data.message,
        isError: false,
      }));
      setState({ type: CLOSE_DIALOG });
      resetFormData();
      reloadData();
    };

    const updateError = (error: any) => {
      setUploadState((prevState) => ({
        ...prevState,
        progress: 0,
        message: `Could not upload the file: ${error}`,
        currentFile: undefined,
        isError: true,
      }));
    };

    const resetSelectedFile = () => {
      setUploadState((prevState) => ({
        ...prevState,
        selectedFiles: undefined,
      }));
    };

    if (values.id) {
      axios.post(`${API}/${values.id}?_method=PUT`, fd, {
        onUploadProgress: (progressEvent) => {
          updateProgress(progressEvent);
        },
      }).then((response) => {
        updateSuccess(response);
      }).catch((error) => {
        updateError(error);
      });
    } else {
      axios.post(API, fd, {
        onUploadProgress: (progressEvent) => {
          updateProgress(progressEvent);
        },
      }).then((response) => {
        updateSuccess(response);
      }).catch((error) => {
        updateError(error);
      });
    }
    resetSelectedFile();
  };

  const reloadData = async () => {
    const response = await getAll({});
    setState({ type: SET_DATA, payload: response.data.data });
  };

  const loadData = () => {
    console.log("loading...");
  };

  const setSelectedFile = (file: any) => {
    console.log("set upload state");
    setUploadState((prevState) => ({
      ...prevState,
      selectedFiles: file,
    }));
  };

  const closeConfirmDialog = () => {
    setState({ type: CLOSE_CONFIRM_DIALOG });
  };

  const deleteInstitution = async () => {
    const response = await deleteItem(state.institution.id);
    if (response.status === 200) {
      reloadData();
      setState({ type: CLOSE_CONFIRM_DIALOG });
    }
  };

  const openConfirmDialog = (item) => {
    setState({ type: OPEN_CONFIRM_DIALOG });
    setState({ type: SET_INSTITUTION, payload: item });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ padding: "15px" }}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Typography variant="h5" noWrap component="div">
                Manage Institutions
              </Typography>
            </Grid>
            <Grid item sm={9}></Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => openDialog(null)}
              >
                Create New
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        {state.data.length > 0 &&
          (
            <Table
              columns={COLUMNS}
              onItemClick={loadData}
              size="small"
              items={state.data}
            >
              {({ row }) => (
                <Slot name="actions">
                  <Tooltip title="Edit Institution" placement="top" arrow>
                    <IconButton onClick={() => openDialog(row)}>
                      <PencilBoxOutline />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Institution" placement="top" arrow>
                    <IconButton onClick={() => openConfirmDialog(row)}>
                      <TrashCanOutline />
                    </IconButton>
                  </Tooltip>
                </Slot>
              )}
            </Table>
          )}
      </Grid>

      <Dialog
        fullWidth
        maxWidth={"sm"}
        title={`${state.title} Institution`}
        open={state.isDialogOpen}
        onCancel={setOpenDialog}
      >
        <CreateForm
          onSubmit={submitForm}
          FORM_DATA={formData}
          uploadState={uploadState}
          onFileSelected={(file) => setSelectedFile(file)}
        />
      </Dialog>

      <ConfirmDialog
        title="Delete Institution"
        isOpen={state.isConfirmDialogOpen}
        text="Are you sure you want to delete this institution?"
      >
        <Button onClick={closeConfirmDialog}>Cancel</Button>
        <Button onClick={deleteInstitution}>Ok</Button>
      </ConfirmDialog>
    </>
  );
};

export default Home;
