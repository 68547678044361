import { LOGOUT_USER, LOGIN_USER, GET_USER } from "store/reducers/auth/action-types";

export interface STATE {
  isLoggedIn: boolean;
  user: Object;
}

interface ACTION {
  type: string;
  payload?: any;
}

const user = JSON.parse(localStorage.getItem("ssoUser")) || null;

const initialState:STATE = {
  isLoggedIn: !!user? true : false,
  user: JSON.parse(localStorage.getItem("ssoUser")) || null,
};

const reducer = (state = initialState, action: ACTION) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, isLoggedIn: true, user: action.payload.user };
    case LOGOUT_USER:
      return { ...state, isLoggedIn: false, user: null };
    case GET_USER:
      return state;
    default:
    return state;
  }
};

export default reducer;
