import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  OPEN_CONFIRM_DIALOG,
  CLOSE_CONFIRM_DIALOG,
  SET_DATA,
  SET_FORM_DATA,
  RESET_FORM_DATA,
  SET_ITEM,
  SET_PERMISSIONS,
  SET_ALL_PERMISSIONS,
  SET_TITLE,
} from "modules/role/reducers/action-types";
import { STATE, ACTION, Role } from "modules/role/types";

const stateReducer = (state: STATE, action: ACTION) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return { ...state, isDialogOpen: true };

    case CLOSE_DIALOG:
      return { ...state, isDialogOpen: false };

    case OPEN_CONFIRM_DIALOG:
      return { ...state, isConfirmDialogOpen: true };

    case CLOSE_CONFIRM_DIALOG:
      return { ...state, isConfirmDialogOpen: false };

    case SET_DATA:
      return { ...state, data: action.payload };

    case SET_ITEM:
      return { ...state, item: action.payload };

    case SET_PERMISSIONS:
      return { ...state, permissions: action.payload }

    case SET_ALL_PERMISSIONS:
      return { ...state, allPermissions: action.payload }

    case SET_TITLE:
      return { ...state, title: action.payload }

    default:
    return state;
  }
};

const formDataReducer = (state: Role, action: ACTION) => {
  switch (action.type) {
    case SET_FORM_DATA:
      return Object.assign({}, action.payload)

    case RESET_FORM_DATA:
      return {...state, formData: action.payload }

    default:
    return state;
  }
};

export { stateReducer, formDataReducer };
