import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "store/reducers/snackbar";
import { bindActionCreators } from "redux";
import { RootState } from "store/store";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface PAYLOAD {
  message: string;
  severity: string;
}

const SnackBar = () => {
  const state = useSelector((state: RootState) => state.snackbar);
  const dispatch = useDispatch();

  const vertical = "bottom";
  const horizontal = "right";

  const payload: PAYLOAD = {
    message: "",
    severity: "",
  };

  const { hideSnackbar } = bindActionCreators(
    actionCreators,
    dispatch,
  );

  const handleClose = () => {
    hideSnackbar(payload);
  };

  return (
    <>
      {state.show && (
        <Snackbar
          open={state.show}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert
            onClose={handleClose}
            severity={state.severity}
            sx={{ width: "100%" }}
          >
            {state.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackBar;
