import axios from "api/axios";

const API = "/companies";

const create = async (payload: any) => {
  return await axios.post(`${API}`, payload)
}

const syncCompanies = async () => {
  return await axios.post(`${API}`)
}

const get = async (params: any) => {
  return await axios.get(`${API}`, {params})
}

export { create, get, syncCompanies  }
