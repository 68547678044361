import { Params } from "components/Table/pagination";
import { SET_PARAMS } from "utils/pagination/action-types";

interface ACTION {
  type: string;
  payload: any;
}

const paginationReducer = (state: Params, action: ACTION) => {
  switch (action.type) {
    case SET_PARAMS:
      return Object.assign({}, action.payload)

    default:
      return state;
  }
}

export default paginationReducer;
