import React, { ReactNode } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table, { TableProps } from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableHead, { TableHeadProps } from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody, { TableBodyProps } from "@mui/material/TableBody";
import TablePagination, { Params } from "components/Table/pagination";
import PropTypes from "prop-types";

import { getSlots } from "components/Slot";

interface T {
  row: Record<any, any>;
  column: string;
}

type ChildProps = T & TableBodyProps & TableHeadProps & ReactNode;

type MyTableProperties<Item, As extends React.ElementType> = {
  items: Item[];
  columns: Array<Column>;
  children: (item: Item) => React.ElementType;
  onItemClick?: Function;
  as?: As;
};

interface MyTableProps {
  columns: Array<Column>;
  items: Array<Object>;
  children?: (props: T) => React.ReactNode;
  rowConfig?: Object;
  onItemClick?: Function;
  showPagination?: boolean;
  onPagination?: Function;
  rows?: Array<number>;
  params?: Params;
}

interface Column {
  label: string;
  value: string;
}

type Props = MyTableProps & TableProps;

const BaseTable = (props: Props) => {
  const {
    columns,
    items,
    children,
    rowConfig,
    onItemClick,
    showPagination = false,
    onPagination,
    rows,
    params,
    ...other
  } = props;

  const [actions] = getSlots(["actions"], children);

  const tableConfig = {
    ...other,
  };

  const rowConfigs = {
    ...rowConfig,
    hover: true,
  };

  const dynamicRenderer = (row: Record<any, any>, column: string) => {
    if (column === "actions") {
      const props = { row, column };
      return children(props);
    } else {
      return row[column];
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table {...tableConfig}>
          <TableHead>
            <TableRow {...rowConfigs}>
              {columns.map((header, i) => (
                <TableCell key={i}>{header.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, i) => (
              <TableRow key={i} {...rowConfigs}>
                {columns.map(({ value }) => (
                  <TableCell key={value}>
                    {dynamicRenderer(item, value)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {showPagination && (
          <TablePagination
            rows={rows}
            params={params}
            onPagination={onPagination}
          />
        )}
      </TableContainer>
    </>
  );
};

BaseTable.defaultProps = {};

BaseTable.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  rowConfig: PropTypes.object,
  onItemClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default BaseTable;
