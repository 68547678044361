import React from "react";
import Box from "@mui/material/Box";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number },
) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(
            props.value,
          )
            }%`}
        </Typography>
      </Box>
    </Box>
  );
};

const LinearWithValueLabel = ({ progress }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
};

interface State {
  currentFile: any;
  progress: number;
  selectedFiles: Array<any>;
  isError: any;
  message: string;
  fileInfos: Array<any>;
  totalElements: number;
}

interface Props {
  onSelectFile: any;
}

interface UploadState {
  state: State;
}

const Upload: React.FC<Props & UploadState> = (props) => {
  const { onSelectFile, state } = props;

  return (
    <>
      {state.currentFile && (
        <Box className="mb25" display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearWithValueLabel
              progress={state.progress}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">
              {`${state.progress} %`}
            </Typography>
          </Box>
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label htmlFor="btn-upload" style={{ width: "100%" }}>
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: "none" }}
              type="file"
              onChange={onSelectFile}
            />
            <Button
              style={{ width: "100%" }}
              variant="outlined"
              component="span"
            >
              Choose Files
            </Button>
          </label>
          <div className="file-name">
            {state.selectedFiles && state.selectedFiles.length > 0
              ? state.selectedFiles[0].name.toUpperCase()
              : null}
          </div>
        </Grid>
      </Grid>
      {/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
      <Typography
        variant="subtitle2"
        className={`upload-message ${state.isError ? "error" : ""}`}
      >
        {state.message}
      </Typography>
    </>
  );
};

export default Upload;
