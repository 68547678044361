import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { Institution, UserInstitution } from "modules/institution/types";

export const can = (action: string, resource: string) : boolean  => {
  const auth = useSelector((state: RootState) => state.auth);
  const user = auth.user;

  if (user && user.permissions) {
    const found = _.find(user.permissions, {
      action,
      friendlyName: resource,
    });

    return !!found;
  }
}

export const canClick = (id: number) => {
  const user = JSON.parse(localStorage.getItem('ssoUser'));
  const found = user.institutions.find((inst: UserInstitution) => inst.institution_id === id);
  return !!found;
}
