import axios from "api/axios";

const API = "/institutions";
const USER_INFO_API = "user-distinct-info";
const PAYLOAD_API = "generate-payload";

interface PAYLOAD {
  tala_reg_no: string;
}

const create = async (payload: any) => {
  return await axios.post(`${API}`, payload)
}

const getAll = async (payload: any) => {
  return await axios.get(`${API}/all`, payload)
}

const updateInstitution = async (id: number) => {
  return await axios.put(`${API}/${id}`)
}

const deleteItem = async (id: number) => {
  return axios.delete(`${API}/${id}`)
}

const list = async (payload: any) => {
  return await axios.get(`${API}`, payload)
}

const getUserInfo = async (id: number) => {
  return await axios.get(`${USER_INFO_API}/${id}`);
}

const generatePayload = async (id: number | string, payload: PAYLOAD) => {
  return await axios.post(`${PAYLOAD_API}/${id}`, payload)
}

export { create, getAll, list, updateInstitution, deleteItem, getUserInfo, generatePayload }
