import React from "react";
import MUIButton, { ButtonProps } from "@mui/material/Button";
import PropTypes from "prop-types";

export interface Props extends ButtonProps {
  label: string;
}

const Button: React.FC<Props> = ({ label, ...otherProps }) => {
  const buttonConfig: ButtonProps = {
    variant: "contained",
    color: "primary",
    size: "medium",
    fullWidth: true,
    ...otherProps,
  };

  return <MUIButton {...buttonConfig}>{label}</MUIButton>;
};

Button.defaultProps = {
  variant: "contained",
  color: "primary",
  size: "medium",
};

Button.propTypes = {
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "success",
    "error",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  onClick: PropTypes.func,
};

export default Button;
