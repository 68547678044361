import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  OPEN_CONFIRM_DIALOG,
  CLOSE_CONFIRM_DIALOG,
  SET_DATA,
  SET_FORM_DATA,
  RESET_FORM_DATA,
  SET_ITEM,
  SET_USER_ROLES,
  SET_TITLE,
} from "modules/user/reducers/action-types";

import { STATE, ACTION, User } from "modules/user/types";

const stateReducer = (state: STATE, action: ACTION) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return { ...state, isDialogOpen: true };

    case CLOSE_DIALOG:
      return { ...state, isDialogOpen: false, item: {}, role: [] };

    case OPEN_CONFIRM_DIALOG:
      return { ...state, isConfirmDialogOpen: true };

    case CLOSE_CONFIRM_DIALOG:
      return { ...state, isConfirmDialogOpen: false };

    case SET_DATA:
      return { ...state, data: action.payload };

    case SET_USER_ROLES:
      return { ...state, roles: action.payload };

    case SET_ITEM:
      return { ...state, item: action.payload };

    case SET_TITLE:
      return { ...state, title: action.payload };

    default:
    return state;
  }
};

const formDataReducer = (state: User, action: ACTION) => {
  switch (action.type) {
    case SET_FORM_DATA:
      return Object.assign({}, action.payload)

    case RESET_FORM_DATA:
      return {...state, formData: action.payload }

    default:
    return state;
  }
};

export { stateReducer, formDataReducer };
