import React, { useEffect, useReducer } from "react";
import Dialog from "components/Dialog";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import Typography from "@mui/material/Typography";
import PencilBoxOutline from "mdi-react/PencilBoxIcon";
import TrashCanOutline from "mdi-react/TrashCanIcon";

import Table from "components/Table";
import ConfirmDialog from "components/ConfirmDialog";
import { Slot } from "components/Slot";
import {
  CLOSE_CONFIRM_DIALOG,
  CLOSE_DIALOG,
  OPEN_CONFIRM_DIALOG,
  OPEN_DIALOG,
  SET_DATA,
  SET_FORM_DATA,
  SET_ITEM,
  SET_TITLE,
  SET_USER_ROLES,
} from "modules/user/reducers/action-types";
import { create, deleteUser, get, updateUser } from "modules/user/services";
import { get as getRoles } from "modules/role/services";
import { User, UserRole } from "modules/user/types";
import { formDataReducer, stateReducer } from "modules/user/reducers";
import { mapUserRoles, mapUsers } from "utils/map-data";
import UserForm from "modules/user/forms/user-form";

// For Pagination Purposes
import paginationReducer from "utils/pagination/reducers";
import { PAGINATION_PARAMS, Params } from "components/Table/pagination";
import { SET_PARAMS } from "utils/pagination/action-types";

const COLUMNS = [
  { id: 2, value: "uuid", label: "UUID" },
  { id: 1, value: "email", label: "Email" },
  { id: 1, value: "full_name", label: "Full Name" },
  { id: 4, value: "tala_reg_no", label: "TALA Reg #" },
  { id: 5, value: "actions", label: "Actions" },
];

const APP_STATE = {
  isDialogOpen: false,
  isConfirmDialogOpen: false,
  item: {
    id: "",
    full_name: "",
    uuid: "",
    email: "",
    tala_reg_no: "",
    roles: [],
  },
  data: [],
  roles: [],
  title: "",
};

const FORM_DATA: User = {
  id: "",
  full_name: "",
  system: "",
  uuid: "",
  tala_reg_no: "",
  email: "",
  roles: [],
};

const Home: React.FC = () => {
  const [state, setState] = useReducer(stateReducer, APP_STATE);
  const [params, setParams] = useReducer(paginationReducer, PAGINATION_PARAMS);
  const [formData, setFormData] = useReducer(
    formDataReducer,
    FORM_DATA,
  );

  useEffect(() => {
    fetchData();

    return () => { };
  }, []);

  const resetFormData = () => {
    const payload = {
      id: "",
      full_name: "",
      system: "",
      uuid: "",
      tala_reg_no: "",
      email: "",
      roles: [],
    };
    setFormData({ type: SET_DATA, payload: payload });
  };

  const fetchData = async () => {
    const response = await get({});
    const { from, to, per_page, current_page, total, last_page } =
      response.data.data;
    const params = {
      from,
      to,
      currentPage: current_page,
      total,
      perPage: per_page,
      lastPage: last_page,
    };
    setParams({ type: SET_PARAMS, payload: params });
    setState({ type: SET_DATA, payload: mapUsers(response.data.data.data) });
  };

  const getPaginatedData = async (parameters: Params) => {
    const response = await get(parameters);
    const { from, to, per_page, current_page, total, last_page } =
      response.data.data;
    const params = {
      from,
      to,
      currentPage: current_page,
      total,
      perPage: per_page,
      lastPage: last_page,
    };
    setParams({ type: SET_PARAMS, payload: params });
    setState({ type: SET_DATA, payload: mapUsers(response.data.data.data) });
  };

  const setOpenDialog = (_val: boolean) => {
    setState({ type: CLOSE_DIALOG });
    resetFormData();
  };

  const openDialog = async (user: User) => {
    const response = await getRoles({});
    const roles = mapUserRoles(response.data.data.data);
    if (user && user.id) {
      setState({ type: SET_TITLE, payload: "Update" });
      setFormData({ type: SET_FORM_DATA, payload: user });
    } else {
      setFormData({ type: SET_FORM_DATA, payload: FORM_DATA });
      setState({ type: SET_TITLE, payload: "Create" });
    }
    setState({ type: OPEN_DIALOG });
    setState({ type: SET_USER_ROLES, payload: roles });
  };

  const closeConfirmDialog = () => {
    setState({ type: CLOSE_CONFIRM_DIALOG });
  };

  const deleteItem = async () => {
    const result = await deleteUser(state.item.id);
    if (result.status === 200) {
      setState({ type: CLOSE_CONFIRM_DIALOG });
      await fetchData();
    }
  };

  const openConfirmDialog = (user: User) => {
    setState({ type: OPEN_CONFIRM_DIALOG });
    setState({ type: SET_ITEM, payload: user });
  };

  const submitForm = async (payload: User) => {
    if (payload && payload.id) {
      const result = await updateUser(payload.id, payload);
      if (result.status === 200) {
        setState({ type: CLOSE_DIALOG });
        await fetchData();
      }
    } else {
      const data = {
        ...payload,
        roles: payload.roles.map((r) => r.id),
      };

      const result = await create(data);
      if (result.status === 201) {
        setState({ type: CLOSE_DIALOG });
        await fetchData();
      }
    }
  };

  const updateRoles = (roles: Array<UserRole>) => {
    const payload = {
      ...FORM_DATA,
      roles: roles,
    };
    setFormData({ type: SET_FORM_DATA, payload: payload });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ padding: "15px" }}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Typography variant="h5" noWrap component="div">
                Manage Users
              </Typography>
            </Grid>
            <Grid item sm={9}></Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => openDialog(null)}
              >
                Create New
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        {state.data.length > 0 &&
          (
            <Table
              columns={COLUMNS}
              onItemClick={fetchData}
              size="small"
              showPagination
              params={params}
              onPagination={getPaginatedData}
              items={state.data}
            >
              {({ row }) => (
                <Slot name="actions">
                  <Tooltip title="Edit User" placement="top" arrow>
                    <IconButton onClick={() => openDialog(row)}>
                      <PencilBoxOutline />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete User" placement="top" arrow>
                    <IconButton onClick={() => openConfirmDialog(row)}>
                      <TrashCanOutline />
                    </IconButton>
                  </Tooltip>
                </Slot>
              )}
            </Table>
          )}
      </Grid>

      <Dialog
        fullWidth
        maxWidth={"sm"}
        title={`${state.title} User`}
        open={state.isDialogOpen}
        onCancel={setOpenDialog}
      >
        <UserForm
          onSubmit={(values) => submitForm(values)}
          FORM_DATA={formData}
          onUpdateRoles={updateRoles}
          roles={state.roles}
        />
      </Dialog>

      <ConfirmDialog
        title="Delete User"
        isOpen={state.isConfirmDialogOpen}
        text="Are you sure you want to delete this user?"
      >
        <Button onClick={closeConfirmDialog}>Cancel</Button>
        <Button onClick={deleteItem}>Ok</Button>
      </ConfirmDialog>
    </>
  );
};

export default Home;
