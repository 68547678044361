import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "components/TextField";
import Button from "components/Button";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Dialog from "components/Dialog";
import { Navigate } from "react-router-dom";
import RegistrationForm from "modules/auth/registration-form";
import { Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import "modules/auth/login.scss";
YupPassword(Yup);

import { login, setupUser, verify } from "modules/auth/services/auth-service";
import { getUrlParamValue } from "utils/get-query-params";

const FORM_INITIAL_STATE = {
  username: "",
  password: "",
};

const STATE = {
  showPassword: false,
  isRegistrationDialogOpen: false,
  redirect: false,
  isVerified: false,
};

const FORM_VALIDATION = Yup.object().shape({
  username: Yup.string().required("* Please fill in your Email/Username"),
  password: Yup.string()
    .required("No password provided.")
    .min(
      8,
      "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special",
    ),
});

const Login: React.FC = () => {
  const [state, setState] = React.useState(STATE);

  useEffect(() => {
    const isVerified = getUrlParamValue.verified;
    setState((prevState) => ({
      ...prevState,
      isVerified: isVerified,
    }));
  }, []);

  const handleClickShowPassword = () => {
    setState((prevState) => ({
      ...prevState,
      showPassword: !state.showPassword,
    }));
  };

  const loginUser = async (values: FormikValues) => {
    const payload = { email: values.username, password: values.password };
    let redirectStatus = false;
    const response = await login(payload);
    if (response.status === 201) {
      redirectStatus = await setupUser(response.data.data.user);
    }
    if (redirectStatus) {
      setState((prevState) => ({
        ...prevState,
        redirect: redirectStatus,
      }));
    }
  };

  const showRegistrationDialog = (evt: any) => {
    evt.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isRegistrationDialogOpen: true,
    }));
  };

  const setOpenDialog = (val: any) => {
    setState((prevState) => ({
      ...prevState,
      isRegistrationDialogOpen: val,
    }));
  };

  const verifyUser = async (values: any) => {
    const response = await verify(values);

    if (response.status === 200) {
      setState((prevState) => ({
        ...prevState,
        isRegistrationDialogOpen: false,
      }));
    }
  };

  return (
    <>
      <Grid container>
        {state.redirect && <Navigate to="/" />}
        {state.isVerified && <Navigate to="/change-password" />}
        <Grid
          spacing={2}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Grid
              spacing={2}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img src="/portal_logo.png" width={100} height={100} />
            </Grid>
            <Grid
              spacing={2}
              container
              justifyContent="center"
              alignItems="center"
            >
              <h2>MNRT SSO</h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={FORM_INITIAL_STATE}
            onSubmit={(values) => loginUser(values)}
            validationSchema={FORM_VALIDATION}
          >
            {({ isValid, dirty, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="username"
                      label="Username"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="password"
                      label="Password"
                      type={state.showPassword ? "text" : "password"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <LockOpenIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {state.showPassword
                                ? <VisibilityOff />
                                : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} sm={12}>
                    <p className="login-text">
                      <a
                        className="login-link"
                        href="#"
                        onClick={(event) => showRegistrationDialog(event)}
                      >
                        Not Signed in? Register
                      </a>
                    </p>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12}>
                    <Button
                      style={{ marginTop: "15px" }}
                      label="Login/Sign In"
                      type="submit"
                      disabled={!dirty || !isValid}
                    />
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </Form>
            )}
          </Formik>
          <Dialog
            fullWidth
            maxWidth={"sm"}
            title="Verify User"
            open={state.isRegistrationDialogOpen}
            onCancel={setOpenDialog}
          >
            <RegistrationForm onSubmit={verifyUser} />
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
