import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";

import ToolBar from "layouts/main/segments/toolbar";
import Sidebar from "layouts/main/segments/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { actionCreators } from "store/reducers/password-change";
import { bindActionCreators } from "redux";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface STATE {
  open: boolean;
  isDialogOpen: boolean;
  isLoggedIn: boolean;
}

const STATE: STATE = {
  open: false,
  isDialogOpen: false,
  isLoggedIn: false,
};

const MainLayout = () => {
  const [state, setState] = React.useState(STATE);

  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const { showDialog } = bindActionCreators(
    actionCreators,
    dispatch,
  );

  React.useEffect(() => {
    setLoginStatus();
  }, [auth]);

  const setLoginStatus = () => {
    if (auth.user && !auth.user.is_password_changed) {
      showDialog();
    }
    setState((prevState) => ({
      ...prevState,
      user: auth.user,
      isLoggedIn: auth.isLoggedIn,
    }));
  };

  const handleDrawerOpen = () => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  };

  const handleDrawerClose = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ToolBar
        open={state.open}
        handleDrawerOpen={() => handleDrawerOpen()}
      />
      <Sidebar
        open={state.open}
        handleDrawerClose={() => handleDrawerClose()}
      />
      <Main open={state.open} sx={{ flexGrow: 1, pl: 6, pt: 4 }}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
};

export default MainLayout;
