import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  OPEN_CONFIRM_DIALOG,
  CLOSE_CONFIRM_DIALOG,
  SET_DATA,
  SET_COMPANY,
  SET_TITLE,
} from "modules/company/reducers/action-types";
import { Company } from "modules/company/types";
import { Params } from "components/Table/pagination";

interface ACTION {
  type: string;
  payload?: any;
}

export interface APP_STATE {
  isDialogOpen: boolean;
  isConfirmDialogOpen: boolean;
  company: Company;
  data: Array<Company>;
  title: string;
  params: Params;
}

export const STATE: APP_STATE = {
  isDialogOpen: false,
  isConfirmDialogOpen: false,
  company: { id: "", name: "", tala_reg_no: "" },
  data: [],
  title: "",
  params:{
    from: null,
    to: null,
    currentPage: null,
    total: null,
    lastPage: null,
    perPage: null,
  },
};

const stateReducer = (state: APP_STATE, action: ACTION) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return { ...state, isDialogOpen: true };

    case CLOSE_DIALOG:
      return { ...state, isDialogOpen: false, title: "", company: {} };

    case OPEN_CONFIRM_DIALOG:
      return { ...state, isConfirmDialogOpen: true };

    case CLOSE_CONFIRM_DIALOG:
      return { ...state, isConfirmDialogOpen: false };

    case SET_DATA:
      return { ...state, data: action.payload };

    case SET_COMPANY:
      return { ...state, company: action.payload };

    case SET_TITLE:
      return { ...state, title: action.payload };

    default:
      return state;
  }
};

export { stateReducer };
