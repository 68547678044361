import { AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { SHOW_PRELOADER, HIDE_PRELOADER  } from "store/reducers/preloader/action-types";
import { RootState } from "store/store";

/**
* Shows the preloader
* @return ThunkAction
*/
export const showPreloader = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SHOW_PRELOADER });
  };
};

/**
* Hides the preloader
* @return ThunkAction
*/
export const hidePreloader = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: Dispatch) => {
    dispatch({ type: HIDE_PRELOADER });
  };
};
