import { SHOW_SNACKBAR, HIDE_SNACKBAR  } from "store/reducers/snackbar/action-types";
import { AlertColor } from "@mui/material/Alert";

export interface STATE {
  show: boolean;
  message: string;
  severity: AlertColor;
}

interface PAYLOAD {
  message: string;
  severity: AlertColor;
}

interface ACTION {
  type: string;
  payload: PAYLOAD;
}

const initialState:STATE = {
  show: false,
  message: "",
  severity: "info"
};

const reducer = (state = initialState, action: ACTION): STATE => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return { ...state, show: true, message: action.payload.message, severity: action.payload.severity };
    case HIDE_SNACKBAR:
      return { ...state, show: false, message: "", severity: "info" };
    default:
      return state;
  }
};

export default reducer;
