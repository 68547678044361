import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import SvgIcon from "@mui/material/SvgIcon";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  background: theme.palette.primary.main,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface Props {
  handleDrawerClose: Function;
  open: boolean;
}

const MENUS = [
  { id: 1, name: "Dashboard", icon: "HomeIcon", routerState: "/" },
  {
    id: 2,
    name: "Manage Institutions",
    icon: "ChevronRightIcon",
    routerState: "/institutions",
  },
  {
    id: 3,
    name: "Manage Users",
    icon: "ChevronRightIcon",
    routerState: "/manage-users",
  },
  {
    id: 4,
    name: "Manage Companies",
    icon: "ChevronRightIcon",
    routerState: "/companies",
  },
  {
    id: 5,
    name: "Manage Roles",
    icon: "ChevronRightIcon",
    routerState: "/manage-roles",
  },
];

const Sidebar: React.FC<Props> = ({ handleDrawerClose, open }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateToPage = (menu) => {
    navigate(`${menu.routerState}`);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <CloseIcon
          color="white"
          style={{ "cursor": "pointer" }}
          component={SvgIcon}
          onClick={() => handleDrawerClose()}
        />
      </DrawerHeader>
      <Divider />
      <List>
        {MENUS.map((menu, idx) => (
          <ListItem key={`${menu.id}-${idx}`} disablePadding>
            <ListItemButton
              onClick={() => navigateToPage(menu)}
            >
              <ListItemIcon>
                {idx % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={menu.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
