import jwt_decode from "jwt-decode";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "store/store";
import { actionCreators } from "store/reducers/login-dialog";

export interface JwtPayload {
  iss: string;
  sub: string;
  aud: string[] | string;
  exp: number;
  nbf: number;
  iat: number;
  jti: string;
}

const RequireValidToken = ({ children }: { children: JSX.Element }) => {
  const auth = useSelector((state: RootState) => state.auth);
  const decodedToken: JwtPayload = jwt_decode(auth.user.token);
  const dispatch = useDispatch();
  const tokenHasExpired = ((Date.now()) >= (decodedToken.exp * 1000));
  const { showDialog } = bindActionCreators(actionCreators, dispatch);
  if (tokenHasExpired) {
    return showDialog();
  } else {
    return children;
  }
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  // console.log("tokenHasExpired", tokenHasExpired);

  if (!auth.isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
};

const RequireNoAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useSelector((state: RootState) => state.auth);
  let location = useLocation();
  if (auth.isLoggedIn) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return children;
  }
};

export { RequireAuth, RequireNoAuth, RequireValidToken };
