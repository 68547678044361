import institutionRoutes from "modules/institution/routes";
import dashboardRoutes from "modules/dashboard/routes";
import authRoutes from "modules/auth/routes";
import roleRoutes from "modules/role/routes";
import userRoutes from "modules/user/routes";
import companyRoutes from "modules/company/routes";
import notFoundRoutes from "modules/404/routes"

const publicRoutes = [
  ...authRoutes,
];

const protectedRoutes  = [
  ...dashboardRoutes,
  ...institutionRoutes,
  ...roleRoutes,
  ...userRoutes,
  ...companyRoutes,
  ...notFoundRoutes,
]

export { publicRoutes, protectedRoutes };

