import React from "react";
import Grid from "@mui/material/Grid";
import Select from "components/Select";
import { Form, Formik } from "formik";
import Button from "components/Button";
import * as Yup from "yup";

const FORM_INITIAL_STATE = {
  tala_reg_no: "",
};

const FORM_VALIDATION = Yup.object().shape({
  tala_reg_no: Yup.string().required("* Please select company"),
});

interface Company {
  id: number;
  name: string;
  description: string;
  url: string;
  imageUrl: string;
}

interface Props {
  companies: Array<Company>;
  onSubmit: Function;
}

const CompanySelectionForm: React.FC<Props> = ({ companies, onSubmit }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Formik
            initialValues={FORM_INITIAL_STATE}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={FORM_VALIDATION}
          >
            {({ isValid, dirty }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Select
                      items={companies}
                      name="tala_reg_no"
                      displayName="name"
                      label="Select A Company You are Logging In As"
                      itemValue="tala_reg_no"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item></Grid>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Button
                      style={{ marginTop: "15px" }}
                      label="Submit"
                      type="submit"
                      disabled={!dirty || !isValid}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanySelectionForm;
