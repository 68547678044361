import axios from "api/axios";
import { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { getUser } from "modules/auth/services/auth-service";
import { store } from "store/store";
import { showPreloader, hidePreloader } from "store/reducers/preloader/actions";
import { showSnackbar }from "store/reducers/snackbar/actions";
import { PAYLOAD } from "store/reducers/snackbar/actions";
import { Dispatch } from "redux";

const SNACKBAR_SEVERITY = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
}

const requestHandler = async (requestConfig: AxiosRequestConfig, dispatch: Dispatch<any> ) => {
  dispatch(showPreloader());
  return requestConfig;
};

const successHandler = async (responseObject: AxiosResponse, dispatch: Dispatch<any>) => {

  const payload: PAYLOAD = {
    message: responseObject.data.message,
    severity: SNACKBAR_SEVERITY.SUCCESS,
  };

  dispatch(hidePreloader());
  switch (responseObject.config.method) {
    case "put":
    case "post":
    case "delete":
      dispatch(showSnackbar(payload));
  }
  return responseObject;
};

const errorHandler = (error: AxiosError, dispatch: Dispatch<any>) => {

  const payload: PAYLOAD = {
    message: `${error.message}: ${error.response.data.errors}`,
    severity: SNACKBAR_SEVERITY.ERROR,
  }
  dispatch(hidePreloader());
  dispatch(showSnackbar(payload));
  return Promise.reject(error);
};


const setup = () => {
  const { dispatch } = store;
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const user = getUser();

    const token = (!!user)? user.token : null;

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return requestHandler(config, dispatch);
  }, (error) => {
      return Promise.reject(error);
    });

  axios.interceptors.response.use((response: AxiosResponse) => {
    return successHandler(response, dispatch);
  }, async (error) => {
      const originalConfig = error.config;

      if (error.response.status === 401) {
        try {
          // check if not expired then get a new <token>
          // or show pop up login dialog
          // otherwise get the token again and make the request

          const user = getUser();
          const token = user.token;

          originalConfig.headers["Authorization"] = `Bearer ${token}`;
          return axios(originalConfig);
        } catch (error) {
          return errorHandler(error, dispatch)
        }
      }
      return errorHandler(error, dispatch)
    });
};

export default setup;
