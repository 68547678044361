import React from "react";
import Typography from "@mui/material/Typography";

export const Home: React.FC = () => {
  return (
    <div>
      <Typography variant="h5" noWrap component="div">
        404: Resource Not Found
      </Typography>
    </div>
  );
};

export default Home;
