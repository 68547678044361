import React, { useEffect, useReducer, useState } from "react";
import Dialog from "components/Dialog";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EyeOutline from "mdi-react/EyeOutlineIcon";
import { Slot } from "components/Slot";
import Table from "components/Table";
import ConfirmDialog from "components/ConfirmDialog";
import { get, syncCompanies as sync } from "modules/company/services";
import { stateReducer } from "modules/company/reducers";
import { Company } from "modules/company/types";
import { useDispatch } from "react-redux";
import { actionCreators } from "store/reducers/preloader";
import { bindActionCreators } from "redux";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import {
  CLOSE_CONFIRM_DIALOG,
  CLOSE_DIALOG,
  OPEN_CONFIRM_DIALOG,
  OPEN_DIALOG,
  SET_COMPANY,
  SET_DATA,
} from "modules/company/reducers/action-types";
import { STATE } from "modules/company/reducers";
import { mapCompanies } from "utils/map-data";

// For Pagination Purposes
import paginationReducer from "utils/pagination/reducers";
import { PAGINATION_PARAMS, Params } from "components/Table/pagination";
import { SET_PARAMS } from "utils/pagination/action-types";

const COLUMNS = [
  { id: 1, value: "name", label: "Name" },
  { id: 2, value: "tala_reg_no", label: "Tala Reg No" },
  { id: 3, value: "actions", label: "Actions" },
];

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useReducer(stateReducer, STATE);
  const [params, setParams] = useReducer(paginationReducer, PAGINATION_PARAMS);

  const dispatch = useDispatch();

  const { showPreloader, hidePreloader } = bindActionCreators(
    actionCreators,
    dispatch,
  );

  const openDialog = (company: Company) => {
    setState({ type: SET_COMPANY, payload: company });
    setState({ type: OPEN_DIALOG });
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const getPaginatedData = async (parameters: Params) => {
    const response = await get(parameters);
    const { from, to, per_page, current_page, total, last_page } =
      response.data.data;
    const params = {
      from,
      to,
      currentPage: current_page,
      total,
      perPage: per_page,
      lastPage: last_page,
    };
    setParams({ type: SET_PARAMS, payload: params });
    const companies = mapCompanies(response.data.data.data);
    setState({ type: SET_DATA, payload: companies });
  };

  const fetchCompanies = async () => {
    const response = await get({});
    const { from, to, per_page, current_page, total, last_page } =
      response.data.data;
    const params = {
      from,
      to,
      currentPage: current_page,
      total,
      perPage: per_page,
      lastPage: last_page,
    };
    setParams({ type: SET_PARAMS, payload: params });
    const companies = mapCompanies(response.data.data.data);
    setState({ type: SET_DATA, payload: companies });
  };

  const closeDialog = () => {
    setState({ type: CLOSE_DIALOG });
  };

  const closeConfirmDialog = () => {
    setState({ type: CLOSE_CONFIRM_DIALOG });
  };

  const openConfirmDialog = async () => {
    setState({ type: OPEN_CONFIRM_DIALOG });
  };

  const syncCompanies = async () => {
    closeConfirmDialog();

    const response = await sync();
    setIsLoading(true);
    showPreloader();

    if (response.status === 200) {
      hidePreloader();
      setIsLoading(false);
    }
    await fetchCompanies();
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ padding: "15px" }}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Typography variant="h5">
                Manage Companies
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => openConfirmDialog()}
              >
                Sync Companies
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        {isLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {state.data.length > 0 &&
          (
            <Table
              columns={COLUMNS}
              size="small"
              showPagination
              params={params}
              onPagination={getPaginatedData}
              // rows={[10, 20, 30, 50, 75, 100, 200]}  =>  use default rows
              items={state.data}
            >
              {({ row }) => (
                <Slot name="actions">
                  <Tooltip title="View Company" placement="top" arrow>
                    <IconButton onClick={() => openDialog(row)}>
                      <EyeOutline />
                    </IconButton>
                  </Tooltip>
                </Slot>
              )}
            </Table>
          )}
      </Grid>

      <Dialog
        fullWidth
        maxWidth={"sm"}
        title={`View Company`}
        open={state.isDialogOpen}
        onCancel={closeDialog}
      >
        {/* {state.company && <h2>Company</h2>} */}
      </Dialog>

      <ConfirmDialog
        title="Sync Companies"
        isOpen={state.isConfirmDialogOpen}
        text="Are you sure you want to sync companies?"
      >
        <Button onClick={closeConfirmDialog}>Cancel</Button>
        <Button onClick={syncCompanies}>Yes</Button>
      </ConfirmDialog>
    </>
  );
};

export default Home;
