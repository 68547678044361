import { AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { SHOW_PASSWORD_DIALOG, HIDE_PASSWORD_DIALOG } from "store/reducers/password-change/action-types";
import { RootState } from "store/store";

export interface PAYLOAD {
  showDialog: boolean;
}

export const showDialog = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SHOW_PASSWORD_DIALOG })
  };
};

export const hideDialog = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: Dispatch) => {
    dispatch({ type: HIDE_PASSWORD_DIALOG });
  };
};
