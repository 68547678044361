import { AnyAction, Dispatch } from "redux";
import { LOGIN_USER, LOGOUT_USER, GET_USER  } from "store/reducers/auth/action-types";
import { User } from "modules/user/types";
import { ThunkAction } from "redux-thunk";
import { RootState } from "store/store";

interface PAYLOAD {
  user: User;
}

export const login = (payload: PAYLOAD ): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: Dispatch) => {
    dispatch({ type: LOGIN_USER, payload: payload  });
  };
};

export const logOut = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: Dispatch) => {
    dispatch({ type: LOGOUT_USER });
  };
};

export const fetchUser = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: Dispatch) => {
    dispatch({ type: GET_USER });
  };
};
