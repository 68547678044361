import React from "react";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Button from "components/Button";
import TextField from "components/TextField";
import DualMultiSelect from "components/DualMultiSelect";
import { UserRole } from "modules/user/types";

const FORM_VALIDATION = Yup.object().shape({
  full_name: Yup.string().required("* Please fill in user full name"),
  email: Yup.string().email("Please enter a valid email address").required(
    "* Please fill in the email",
  ),
  tala_reg_no: Yup.string().required(
    "* Please fill in tala registration #",
  ),
});

interface FORM_DATA {
  id?: number;
  email: string;
  full_name: string;
  tala_reg_no: string;
  roles: Array<UserRole>;
}

interface Props {
  onSubmit: Function;
  FORM_DATA: FORM_DATA;
  roles: Array<UserRole>;
  onUpdateRoles?: Function;
}

const UserForm: React.FC<Props> = (props) => {
  const { onSubmit, FORM_DATA, roles, onUpdateRoles } = props;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Formik
            initialValues={FORM_DATA}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={FORM_VALIDATION}
          >
            {({ isValid, dirty, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      name="full_name"
                      label="Full Name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="email"
                      label="User Email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="tala_reg_no"
                      label="TALA Registration #"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DualMultiSelect
                      items={roles}
                      selectedItems={FORM_DATA.roles}
                      onAddRemove={onUpdateRoles}
                      label="Select Role"
                      rightLabel="Selected Roles"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Button
                      style={{ marginTop: "15px" }}
                      label="Save"
                      type="submit"
                      disabled={!dirty || !isValid}
                    />
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};
export default UserForm;
