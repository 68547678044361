import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AppLayout from "layouts/main";
import NonAuthLayout from "layouts/non-auth";
import Preloader from "components/Preloader";
import SnackBar from "components/Snackbar";
import PasswordDialog from "components/PasswordDialog";
import LoginDialog from "components/LoginDialog";

import {
  RequireAuth,
  RequireNoAuth,
  RequireValidToken,
} from "routes/require-auth";
import { protectedRoutes, publicRoutes } from "routes";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Preloader />
      <SnackBar />
      <PasswordDialog />
      <LoginDialog />
      <Routes>
        {/* protected routes */}
        <Route path="/" element={<AppLayout />}>
          {protectedRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <RequireAuth>
                  <RequireValidToken>
                    <route.component />
                  </RequireValidToken>
                </RequireAuth>
              }
            />
          ))}
        </Route>
        {/* public routes */}
        <Route path="/" element={<NonAuthLayout />}>
          {publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <RequireNoAuth>
                  <route.component />
                </RequireNoAuth>
              }
            />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
