import { combineReducers } from "redux";
import preloader from "store/reducers/preloader/reducers";
import snackbar from "store/reducers/snackbar/reducers";
import auth from "store/reducers/auth/reducers";
import passwordDialog from "store/reducers/password-change/reducers";
import loginDialog from "store/reducers/login-dialog/reducers";

const reducer = combineReducers({
  preloader,
  snackbar,
  auth,
  passwordDialog,
  loginDialog,
});

export default reducer;
