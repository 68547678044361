import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
    paddingRight: 0,
    paddingLeft: 5,
    paddingBottom: 10,
  },
}));

export interface Params {
  from: number;
  to: number;
  currentPage: number;
  total: number;
  lastPage: number;
  perPage: number;
}

export const PAGINATION_PARAMS: Params = {
  from: null,
  to: null,
  currentPage: null,
  total: null,
  lastPage: null,
  perPage: null,
};

interface Props {
  rows: Array<number>;
  params: Params;
  onPagination: Function;
}

const Pagination: React.FC<Props> = (
  { rows = [5, 10, 20, 50, 100, 200], params, onPagination },
) => {
  const classes = useStyles();
  const [val, setVal] = useState(params.perPage);

  /**
   * gets selected event's value from the UI and set it as the current
   * value of the page
   * @param event: React.Event
   * return void
   */
  const handleChange = (event: any) => {
    setVal(event.target.value);
    onPagination({ per_page: event.target.value });
  };

  /**
   * checks if previious button is disabled
   * @return boolean
   */
  const isPrevBtnDisabled = (): boolean => {
    return params.currentPage === 1 ? true : false;
  };

  /**
   * checks if next button is disabled
   * @return boolean
   */
  const isNextBtnDisabled = (): boolean => {
    return params.currentPage === params.total ||
      params.currentPage === params.lastPage;
  };

  /**
   * navigates to the next page by incrementing the current page by 1
   * @return void
   */
  const goToNextPage = (): void => {
    const currentPage = params.currentPage + 1;
    let payload = {
      per_page: val,
      page: currentPage,
    };
    onPagination(payload);
  };

  /**
   * navigates to the previious page by decrementing the current page by 1
   * @return void
   */
  const goToPreviousPage = (): void => {
    let previousPage = params.currentPage - 1;
    let payload = {
      per_page: val,
      page: previousPage,
    };
    onPagination(payload);
  };

  return (
    <>
      <Grid
        container
        className={classes.root}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box mr={2}>Per Page</Box>{" "}
        <Box mr={2}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={val}
            label="Page"
            onChange={(event) => handleChange(event)}
          >
            {rows.map((row) => <MenuItem key={row} value={row}>{row}
            </MenuItem>)}
          </Select>
        </Box>
        <Box>
          {params.from}-{params.to} of {params.total}
        </Box>
        <Box>
          <IconButton disabled={isPrevBtnDisabled()} onClick={goToPreviousPage}>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton disabled={isNextBtnDisabled()} onClick={goToNextPage}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Grid>
    </>
  );
};

export default Pagination;
