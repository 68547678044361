import { Role, Permission } from "modules/role/types";
import { Institution, UserInstitution  } from "modules/institution/types";
import { User, UserRole } from "modules/user/types";
import { Company } from "modules/company/types";

const LOGO_UPLOAD_PATH = process.env.REACT_APP_LOGO_PATH;

/**
* Take permissions and apply the map function on each permission
* and return a cleaner permission array
* @param permissions: Array<any>
* @return Array<Institution>
*
**/
const mapPermissions = (permissions: Array<any>): Array<any> => {
  return permissions.map(mapPermission)
}

/**
* Take a single role and sanitize  and return
* return Role
* @param role: Role
* @return Role
*/

const mapRole = (role: Role): Role => {
  const { id, name, code, description, permissions: perm } = role;
  return {
    id,
    name,
    code,
    description,
    permissions: mapPermissions(perm)
  }
}

/**
* Take user roles and apply the map function on each role
* and return a cleaner role array
* @param roles: Array<any>
* @return Array<UserRole>
*
**/
const mapUserRoles = (roles: Array<any>): Array<UserRole> => {
  return roles.map(mapUserRole);
}

/**
* Take a single role and sanitize  and return
* return Role
* @param role: Role
* @return Role
*/

const mapUserRole = (role: UserRole): Role => {
  const { id, name, code, description } = role;
  return {
    id,
    name,
    code,
    description,
  }
}

/**
* Take a single permission and sanitize and return it
* @param permission: Permission
* @return Permission
*/
const mapPermission = (permission: Permission): Permission  => {
  const {id, name, method, action, controller} = permission;
  return {
    id,
    name,
    method,
    controller,
    action,
    friendlyName: name.split(" ")[0],
  }
}


/**
* Take institutions and apply the map function on each institution
* and return a cleaner institution array
* @param institutions: Array<any>
* @return Array<Institution>
*
**/
const mapInstitutions = (institutions: Array<any>): Array<Institution> => {
  return institutions.map(mapInstitution);
}

/**
* Take a single institution and sanitize and return it
* @param institution: Institution
* @return Institution
*/
const mapInstitution = (institution: Institution): Institution => {
  const { id, name, system_id, description, url, logo_path, keypass, alias  } = institution;
  return {
    id,
    name,
    system_id,
    description,
    url,
    imageUrl: `${LOGO_UPLOAD_PATH}/${logo_path}`,
    keypass,
    alias,
  }
}

/**
* Take roles and apply the map function on each role
* and return a cleaner role array
* @param roles: Array<any>
* @return Array<any>
*
**/
const mapRoles = (roles: Array<any>): Array<Role> => {
  return roles.map(mapRole);
}

/**
* Take user institutions and apply the map function on each institution
* and return a cleaner institution array
* @param institutions: Array<any>
* @return Array<UserInstitution>
*
**/
const mapUserInstitutions = (institutions: Array<any>): Array<UserInstitution> => {
  return institutions.map(mapUserInstitution);
}

/**
* Take a single UserInstitution and sanitize and return it
* @param institution: UserInstitution
* @return UserInstitution
*
**/
const mapUserInstitution = (institution: UserInstitution): UserInstitution  => {
  const { id, institution_id, tala_reg_no, user_id, username  } = institution;
  return {
    id,
    institution_id,
    tala_reg_no,
    user_id,
    username,
  }
}


/**
* Take user, apply the map function on each user
* and return a cleaner user array
* @param users: Array<any>
* @return Array<User>
*
**/
const mapUsers = (users: Array<any>): Array<User> => {
  return users.map(mapUser);
}

/**
* Take a single User and sanitize and return it
* @param user: User
* @return User
*
**/
const mapUser = (user: User): User  => {
  const { id, full_name, tala_reg_no, email, system, uuid, roles: userRoles } = user;
  return {
    id,
    full_name,
    tala_reg_no,
    email,
    system,
    uuid,
    roles: mapUserRoles(userRoles),
  }
}


/**
* Take company, apply the map function on each company
* and return a cleaner user array
* @param companies: Array<any>
* @return Array<Company>
*
**/
const mapCompanies = (companies: Array<any>): Array<Company> => {
  return companies.map(mapCompany);
}

/**
* Take a single company and sanitize and return it
* @param company: Company
* @return Company
*
**/
const mapCompany = (company: Company): Company  => {
  const { id, name, tala_reg_no } = company;
  return {
    id,
    name,
    tala_reg_no,
  }
}

export {
  mapRoles,
  mapPermissions,
  mapPermission,
  mapRole,
  mapInstitutions,
  mapUserInstitutions,
  mapUsers,
  mapUserRoles,
  mapCompanies
};
