import React, { FC, ReactElement, ReactNode } from "react";

interface Props {
  name: string;
  children: ReactNode;
}

const Slot: FC<Props> = ({ children, ...otherProps }): ReactElement => (
  <span {...otherProps}>{children}</span>
);

const getSlots = (names: Array<string>, children: ReactNode) => {
  return names.map((name) => {
    let slot = null;
    React.Children.forEach(children, (child: ReactNode) => {
      if (!React.isValidElement(child)) {
        return;
      }
      if (child.type === Slot && (child.props).name === name) {
        slot = React.cloneElement(child);
      }
    });
    return slot;
  });
};

export { getSlots, Slot };
