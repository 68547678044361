import axios from "api/axios";
import { AxiosResponse } from "axios";
const API = "/users"

const get = async (params: Record<any, any>): Promise<AxiosResponse> => {
  return await axios.get(`${API}`, { params });
}

const find = async (id: number | string): Promise<AxiosResponse> => {
  return await axios.get(`${API}/${id}`);
}

const create = async (payload: Record<any, any>): Promise<AxiosResponse> => {
  return await axios.post(`${API}`, payload);
}

const updateUser = async (id: number | string, payload: Record<any, any>): Promise<AxiosResponse> => {
  return await axios.put(`${API}/${id}`, payload);
}

const deleteUser = async (id: number | string): Promise<AxiosResponse> => {
  return await axios.delete(`${API}/${id}`);
}

export { get, find, create, updateUser, deleteUser }
