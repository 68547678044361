import React from "react";
import { Outlet } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    // background: "linear-gradient(45deg, #9013FE 15%, #50E3C2 90%)",
    background: "#5ba89a",
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  paper: {
    width: "450px",
    minHeight: "20vh",
    display: "flex",
    padding: "30px",
    alignItems: "center",
  },
});

const NonAuthLayout: React.FC = () => {
  const classes = useStyles();

  const paperStyle = {
    padding: "20px",
    width: "440px",
  };

  return (
    <Grid
      className={classes.root}
      container
      spacing={0}
      justifyContent="center"
      alignItems="center"
    >
      <Paper elevation={2} className={classes.paper}>
        <Outlet />
      </Paper>
    </Grid>
  );
};

export default NonAuthLayout;
