import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const STATE = {
  selectedItems: [],
  items: [],
};

const paperStyles = {
  marginBottom: "20px",
};

const PermissionList: React.FC = (
  { permission, selectedItems, onSubmit, onItemCheked },
) => {
  const { permissions: rows } = permission;
  const [state, setState] = React.useState(STATE);

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectedItems: selectedItems,
      items: rows,
    }));

    return () => {
      console.log("run useEffect because values changed");
    };
  }, [rows, selectedItems]);

  const checkSelected = (row) => {
    const result = state.selectedItems.find((entry) =>
      parseInt(entry.id) === parseInt(row.id)
    );
    return result ? true : false;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;
    onItemCheked(selectedId);
  };

  return (
    <Grid item xs={12} md={3} lg={3}>
      <Paper elevation={3} style={paperStyles}>
        <Grid item>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ borderBottom: "1px solid #ccc" }}>
                  <TableCell>{permission.name.toUpperCase()}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.items.map((row, idx) => (
                  <TableRow hover key={idx} selected={checkSelected(row)}>
                    <TableCell style={{ padding: "5px 5px 5px 20px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            inputProps={{ "value": row.id }}
                            checked={checkSelected(row)}
                          />
                        }
                        label={row.action}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PermissionList;
