import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import InstitutionCard from "modules/dashboard/components/institution-card";
import { getAll } from "modules/institution/services/institution-service";
import { mapInstitutions } from "utils/map-data";

const STATE = {
  institutions: [],
};

const Dashboard = () => {
  const [state, setState] = useState(STATE);
  const styles = {
    paddingBottom: "10px",
    fontWeight: "normal",
  };

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getAll({}).then((response) => {
      setState((prevState) => ({
        ...prevState,
        institutions: mapInstitutions(response.data.data),
      }));
    });
  };

  //currying
  const displayInstitution = (item: any) => {
    return <InstitutionCard item={item} key={item.id} />;
  };

  return (
    <Container>
      <h2 style={styles}>MNRT SSO DASHBOARD</h2>
      <Grid container spacing={2}>
        {state.institutions.map(displayInstitution)}
      </Grid>
    </Container>
  );
};

export default Dashboard;
