import axios from "api/axios";
import { AxiosResponse } from "axios";
const API = "/roles"
const PERMISSION_API = "/permissions";

const get = async (params: Record<any, any>): Promise<AxiosResponse> => {
  return await axios.get(`${API}`, { params });
}

const find = async (id: number | string): Promise<AxiosResponse> => {
  return await axios.get(`${API}/${id}`);
}

const create = async (payload: Record<any, any>): Promise<AxiosResponse> => {
  return await axios.post(`${API}`, payload);
}

const addPermissions = async (payload: Record<any, any>): Promise<AxiosResponse> => {
  return await axios.post(`${API}/permissions`, payload);
}

const updateRole = async (id: number | string, payload: Record<any, any>): Promise<AxiosResponse> => {
  return await axios.put(`${API}/${id}`, payload);
}

const deleteRole = async (id: number | string): Promise<AxiosResponse> => {
  return await axios.delete(`${API}/${id}`);
}

const getPermissions = async(): Promise<AxiosResponse> => {
  return await axios.get(`${PERMISSION_API}`);
}

const getAllPermissions = async(): Promise<AxiosResponse> => {
  return await axios.get(`${PERMISSION_API}/all`);
}

export { get, find, create, updateRole, deleteRole, getPermissions, getAllPermissions, addPermissions }
