import React from "react";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Button from "components/Button";
import Upload from "components/Upload";
import TextField from "components/TextField";

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("* Please fill in institution name"),
  description: Yup.string().required(
    "* Please fill in institution description",
  ),
  url: Yup.string().required("* Please fill in institution url"),
  system_id: Yup.string().required("* Please fill in institution identifier"),
});

interface FORM_DATA {
  id?: number;
  name: string;
  description: string;
  system_id: string;
  logo_path: string;
  keypass?: string;
  alias?: string;
  url: string;
}

interface Props {
  onSubmit: Function;
  FORM_DATA: FORM_DATA;
  onFileSelected: Function;
  uploadState: any;
}

const CreateForm: React.FC<Props> = (props) => {
  const { onSubmit, onFileSelected, uploadState, FORM_DATA } = props;

  const setSelecteFile = (event: any) => {
    onFileSelected(event.target.files);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Formik
            initialValues={FORM_DATA}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={FORM_VALIDATION}
          >
            {({ isValid, dirty, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      name="alias"
                      label="Alias"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="name"
                      label="Institution Name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Description"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="system_id"
                      label="System"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="url"
                      label="Redirect URL"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="keypass"
                      label="KeyPass"
                      multiline={true}
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Upload
                      state={uploadState}
                      onSelectFile={setSelecteFile}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Button
                      style={{ marginTop: "15px" }}
                      label="Save"
                      type="submit"
                      disabled={!dirty || !isValid}
                    />
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};
export default CreateForm;
