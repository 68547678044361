import React from "react";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Button from "components/Button";
import TextField from "components/TextField";

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("* Please fill in institution name"),
  code: Yup.string().required(
    "* Please fill in role code",
  ),
  description: Yup.string().required(
    "* Please fill in role description",
  ),
});

interface FORM_DATA {
  id?: number;
  name: string;
  code: string;
  description: string;
}

interface Props {
  onSubmit: Function;
  FORM_DATA: FORM_DATA;
}

const RoleForm: React.FC<Props> = (props) => {
  const { onSubmit, FORM_DATA } = props;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Formik
            initialValues={FORM_DATA}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={FORM_VALIDATION}
          >
            {({ isValid, dirty, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      name="code"
                      label="Code"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name="name"
                      label="Role Name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Description"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Button
                      style={{ marginTop: "15px" }}
                      label="Save"
                      type="submit"
                      disabled={!dirty || !isValid}
                    />
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};
export default RoleForm;
