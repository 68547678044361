import { AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { SHOW_SNACKBAR, HIDE_SNACKBAR  } from "store/reducers/snackbar/action-types";
import { RootState } from "store/store";

export interface PAYLOAD {
  message: string;
  severity: string;
}

export const showSnackbar = (payload: PAYLOAD): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SHOW_SNACKBAR, payload});
  };
};

export const hideSnackbar = (payload: PAYLOAD): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: Dispatch) => {
    dispatch({ type: HIDE_SNACKBAR, payload });
  };
};
