import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "components/TextField";
import Button from "components/Button";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import "modules/auth/login.scss";
YupPassword(Yup);

import { login, setupUser, verify } from "modules/auth/services/auth-service";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "store/reducers/login-dialog";

const FORM_INITIAL_STATE = {
  username: "",
  password: "",
};

const STATE = {
  showPassword: false,
  isRegistrationDialogOpen: false,
  redirect: false,
  isVerified: false,
};

const FORM_VALIDATION = Yup.object().shape({
  username: Yup.string().required("* Please fill in your Email/Username"),
  password: Yup.string()
    .required("No password provided.")
    .min(
      8,
      "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special",
    ),
});

const LoginForm: React.FC = () => {
  const [state, setState] = React.useState(STATE);
  const dispatch = useDispatch();
  const { hideDialog } = bindActionCreators(actionCreators, dispatch);

  const handleClickShowPassword = () => {
    setState((prevState) => ({
      ...prevState,
      showPassword: !state.showPassword,
    }));
  };

  const loginUser = async (values: FormikValues) => {
    const payload = { email: values.username, password: values.password };
    let redirectStatus = false;
    const response = await login(payload);
    if (response.status === 201) {
      hideDialog();
      redirectStatus = await setupUser(response.data.data.user);
    }
    if (redirectStatus) {
      setState((prevState) => ({
        ...prevState,
        redirect: redirectStatus,
      }));
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Formik
            initialValues={FORM_INITIAL_STATE}
            onSubmit={(values) => loginUser(values)}
            validationSchema={FORM_VALIDATION}
          >
            {({ isValid, dirty, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="username"
                      label="Username"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="password"
                      label="Password"
                      type={state.showPassword ? "text" : "password"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <LockOpenIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {state.showPassword
                                ? <VisibilityOff />
                                : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item></Grid>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Button
                      style={{ marginTop: "15px" }}
                      label="Login/Sign In"
                      type="submit"
                      disabled={!dirty || !isValid}
                    />
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginForm;
