import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "components/TextField";
import Button from "components/Button";

import YupPassword from "yup-password";
YupPassword(Yup);

import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";

const FORM_INITIAL_STATE = {
  email: "",
  tala_reg_no: "",
};

const FORM_VALIDATION = Yup.object().shape({
  email: Yup.string().email("Invalid email format").required(
    "* Please fill in your Email/Username",
  ),
  tala_reg_no: Yup.string()
    .required("No TALA registration number provided"),
});

interface RegistrationFormProps {
  onSubmit: Function;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ onSubmit }) => {
  const submit = (values: FormikValues, helpers: FormikHelpers<any>) => {
    onSubmit(values);
    helpers.resetForm();
  };

  return (
    <>
      <Grid container>
        <Grid
          spacing={2}
          container
          justifyContent="center"
          alignItems="center"
        >
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={FORM_INITIAL_STATE}
            onSubmit={(values, helpers) => submit(values, helpers)}
            validationSchema={FORM_VALIDATION}
          >
            {({ isValid, dirty, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label="Email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="tala_reg_no"
                      label="TALA Registration #"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Button
                      style={{ marginTop: "15px" }}
                      label="Register/Sign In"
                      type="submit"
                      disabled={!dirty || !isValid}
                    />
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default RegistrationForm;
