import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Typography from "@mui/material/Typography/Typography";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  paper: { minWidth: "650px", width: "auto" },
  divider: { padding: 0 },
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    "& div": {
      color: "white",
    },
  },
  dialogWrapper: {
    padding: theme.spacing(0),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  icon: {
    cursor: "pointer",
  },
}));

interface Props {
  title: string;
  text: string;
  isOpen: boolean;
  children: React.ReactNode;
}

const ConfirmDialog: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { title, text, children, isOpen, ...otherProps } = props;

  const dialogConfig = {
    ...otherProps,
  };

  return (
    <Dialog
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={isOpen}
      {...dialogConfig}
    >
      <DialogTitle className={classes.root} id="confirmation-dialog-title">
        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ "padding": "20px 20px 0 30px" }}>
        {text}
      </DialogContent>
      <DialogActions style={{ "padding": "20px" }}>
        {children}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
