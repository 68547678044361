import { AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { SHOW_LOGIN_DIALOG, HIDE_LOGIN_DIALOG  } from "store/reducers/login-dialog/action-types";
import { RootState } from "store/store";

export interface PAYLOAD {
  showDialog: boolean;
}

export const showDialog = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SHOW_LOGIN_DIALOG })
  };
};

export const hideDialog = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: Dispatch) => {
    dispatch({ type: HIDE_LOGIN_DIALOG });
  };
};
