import { SHOW_PRELOADER, HIDE_PRELOADER  } from "store/reducers/preloader/action-types";

interface STATE {
  loading: boolean;
}

interface ACTION {
  type: string;
  payload?: any;
}

const initialState:STATE = {
  loading: false,
};

const reducer = (state = initialState, action: ACTION) => {
  switch (action.type) {
    case SHOW_PRELOADER:
      return { ...state, loading: true };
    case HIDE_PRELOADER:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
