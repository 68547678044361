import axios from "api/axios";
import { store } from "store/store";
import { logOut, login as loginUser } from "store/reducers/auth/actions";
import { LOGIN_USER } from "store/reducers/auth/action-types";
import { mapRoles, mapPermissions,mapUserInstitutions } from "utils/map-data";

const LOGIN_API = "login";
const REGISTRATION_API = "verify";
const CHANGE_PASSWORD_API = "/change-password";
const LOGOOUT_API = "logout";

interface LoginPayload {
  email: string;
  password: string;
}

interface RegistrationPayload {
  email: string;
  tala_reg_no: string;
}

const login = async (payload: LoginPayload) => {
  return await axios.post(`${LOGIN_API}`,  payload );
};

const verify = async (payload: RegistrationPayload) => {
  return await axios.post(`${REGISTRATION_API}`, payload)
}


const setupUser = async (user: any) => {
  const { dispatch } = store;
  const {
    id,
    full_name,
    email,
    is_password_changed,
    tala_reg_no,
    system,
    token,
    uuid,
    roles,
    permissions,
    institutions: companies,
  } = user;

  const userPayload = {
    id,
    full_name,
    email,
    is_password_changed,
    tala_reg_no,
    system,
    token,
    uuid,
    institutions: mapUserInstitutions(companies),
    roles: mapRoles(roles),
    permissions: mapPermissions(permissions),
  }

  localStorage.setItem("ssoUser", JSON.stringify(userPayload));

  const payload = {
    type: LOGIN_USER,
    user: userPayload ,
  }

  dispatch(loginUser(payload))
  return true;
};

const getUser = () => {
  const user = localStorage.getItem('ssoUser');
  if (user) {
    return JSON.parse(user);
  } else {
    return null;
  }
}

const changePassword = async (payload: any) => {
  return await axios.post(`${CHANGE_PASSWORD_API}`, payload)
}

const signOut = async() => {
  const { dispatch } = store;

  const response = await axios.post(`${LOGOOUT_API}`);
  if (response.status === 200) {
    dispatch(logOut());
    localStorage.removeItem("ssoUser");
    return true;
  } else {
    return false;
  }

}

export { login, verify, setupUser, getUser, changePassword, signOut }
