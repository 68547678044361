import * as React from "react";
import MUIDialog, { DialogProps } from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export interface Props extends DialogProps {
  title: string;
  open: boolean;
  onCancel: Function;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: { minWidth: "650px", width: "auto" },
  divider: { padding: 0 },
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    "& div": {
      color: "white",
    },
  },
  dialogWrapper: {
    padding: theme.spacing(0),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  icon: {
    cursor: "pointer",
  },
}));

const Dialog: React.FC<Props> = (props: Props) => {
  const { title, children, open, onCancel, ...otherProps } = props;
  const classes = useStyles();

  const dialogConfig = {
    open,
    ...otherProps,
  };

  return (
    <div>
      <MUIDialog {...dialogConfig}>
        <DialogTitle className={classes.root}>
          <Grid container>
            <Grid item>
              <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                {title.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs></Grid>
            <Grid item>
              <CloseIcon
                className={classes.icon}
                onClick={() => {
                  onCancel(false);
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers style={{ minHeight: "130px", height: "auto" }}>
          {children}
        </DialogContent>
      </MUIDialog>
    </div>
  );
};

export default Dialog;
